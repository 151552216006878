import classNames from "classnames"
import { QuadrantSortArea } from "../../types"
import { AxesIcon } from "./AxesIcon"
import styles from "./QuadrantSortGridControlStyles.module.css"
import { compareAreas } from "../../utils"

interface QuadrantSortGridControlProps {
  // xAreas: number,
  // yAreas?: number
  sizeRem: number
  activeAreas: QuadrantSortArea[]
}

export function QuadrantSortGridControl(props: QuadrantSortGridControlProps) {
  const { activeAreas, sizeRem } = props
  // TODO пропсы
  const xAreas = 2
  const yAreas = 2
  const gap = 0.4 // rem, between areas on the schema

  return (
    <div
      className={styles.gridControlContainer}
      style={{ width: `${sizeRem}rem`, height: `${sizeRem}rem` }}
    >
      <div className={classNames(styles.gridControlAbsolute, styles.gridControlXAreasBlock)}>
        {Array.from({ length: xAreas }, (_, index) => (
          <div
            key={index}
            className={classNames(styles.gridControlXArea)}
            style={{
              width: `${(sizeRem - gap * (xAreas - 1)) / xAreas}rem`,
              opacity: `${compareAreas(activeAreas, { axis: "X", index: index }) ? 0.5 : 0.2}`
            }}
          />
        ))}
      </div>
      <div className={classNames(styles.gridControlAbsolute, styles.gridControlYAreasBlock)}>
        {yAreas &&
          Array.from({ length: yAreas }, (_, index) => (
            <div
              key={index}
              className={classNames(styles.gridControlYArea)}
              style={{
                height: `${(sizeRem - gap * (yAreas - 1)) / yAreas}rem`,
                opacity: `${compareAreas(activeAreas, { axis: "Y", index: index }) ? 0.5 : 0.2}`
              }}
            />
          ))}
      </div>
      <div className={styles.gridControlAbsolute}>
        <AxesIcon sizeRem={sizeRem} />
      </div>
    </div>
  )
}
