import { relative } from "path"
import { useAppSelector } from "../../../../../store/store"

interface AxesProps {
  widthRem: number
  heightRem: number
}

export function Axes(props: AxesProps) {
  const { widthRem, heightRem } = props
  const remValue = useAppSelector(state => state.appReducer.remValue)

  const axisPaddingPx = 1 * remValue

  return (
    <svg
      width={`${widthRem * remValue + axisPaddingPx * 2}px`}
      height={`${heightRem * remValue + axisPaddingPx * 2}px`}
      viewBox={`
        0
        0
        ${widthRem * remValue} 
        ${heightRem * remValue}
      `}
      xmlns="http://www.w3.org/2000/svg"
      style={{ zIndex: 2, position: "absolute", bottom: 0, left: 0, pointerEvents: "none"}}
    >
      {/* X-axis */}
      {/* <line x1="-325" y1="500" x2="1325" y2="500" stroke="black" strokeWidth="1" />
      <polygon points="1309, 490 1309, 510 1341, 500" fill="gray" />
      <polygon points="-309, 490 -309, 510 -341, 500" fill="gray" /> */}

      {/* Y-axis */}
      {/* <line x1="500" y1="25" x2="500" y2="975" stroke="black" strokeWidth="1" />
      <polygon points="490, 41 510, 41 500, 9" fill="gray" />
      <polygon points="490, 959 510, 959 500, 991" fill="gray" /> */}

      {/* X-axis */}
      <line
        x1={`${axisPaddingPx * 0.25}`}
        y1={`${heightRem * remValue + axisPaddingPx * 0.2}`}
        x2={`${widthRem * remValue - 5}`}
        y2={`${heightRem * remValue + axisPaddingPx * 0.2}`}
        stroke="#323232"
        strokeWidth="1"
      />
      <polygon
        points={`
          ${widthRem * remValue},${heightRem * remValue + axisPaddingPx * 0.2}
          ${widthRem * remValue - 10},${heightRem * remValue + axisPaddingPx * 0.2 - 3}
          ${widthRem * remValue - 10},${heightRem * remValue + axisPaddingPx * 0.2 + 3}
          ${widthRem * remValue},${heightRem * remValue + axisPaddingPx * 0.2}
        `}
        fill="#323232"
      />

      {/* Y-axis */}
      <line
        x1={`${axisPaddingPx * 0.25}`}
        y1={`-${axisPaddingPx * 0.2 - 5}`}
        x2={`${axisPaddingPx * 0.25}`}
        y2={`${heightRem * remValue + axisPaddingPx * 0.2}`}
        stroke="#323232"
        strokeWidth="1"
      />
      <polygon
        points={`
          ${axisPaddingPx * 0.25},${-axisPaddingPx * 0.25} 
          ${axisPaddingPx * 0.25 - 3},${-axisPaddingPx * 0.25 + 10} 
          ${axisPaddingPx * 0.25 + 3},${-axisPaddingPx * 0.25 + 10} 
          ${axisPaddingPx * 0.25},-${axisPaddingPx * 0.25} 
        `}
        fill="#323232"
      />
    </svg>
  )
}
