// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconButtonStylesTemp_iconButtonTemp__jWdQO {\n  display: flex;\n  border: none;\n  background-color: #FFF;\n  cursor: pointer;\n  align-items: center;\n  /* gap: 0.25rem; */\n}\n\n.IconButtonStylesTemp_iconButtonTemp__jWdQO > svg {\n  stroke: #9D9D9D;\n  transition: 0.1s;\n}\n\n.IconButtonStylesTemp_iconButtonTemp__jWdQO:hover > svg {\n  stroke: #656565;\n}\n\n.IconButtonStylesTemp_iconButtonTemp__jWdQO > p {\n  color: #323232;\n  font-size: 0.875rem;\n  font-weight: 400;\n  text-decoration-line: underline;\n}\n", "",{"version":3,"sources":["webpack://./src/project/UI/IconButtonTemp/IconButtonStylesTemp.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,sBAAsB;EACtB,eAAe;EACf,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,gBAAgB;EAChB,+BAA+B;AACjC","sourcesContent":[".iconButtonTemp {\n  display: flex;\n  border: none;\n  background-color: #FFF;\n  cursor: pointer;\n  align-items: center;\n  /* gap: 0.25rem; */\n}\n\n.iconButtonTemp > svg {\n  stroke: #9D9D9D;\n  transition: 0.1s;\n}\n\n.iconButtonTemp:hover > svg {\n  stroke: #656565;\n}\n\n.iconButtonTemp > p {\n  color: #323232;\n  font-size: 0.875rem;\n  font-weight: 400;\n  text-decoration-line: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconButtonTemp": "IconButtonStylesTemp_iconButtonTemp__jWdQO"
};
export default ___CSS_LOADER_EXPORT___;
