import classNames from "classnames"
import {
  CSSProperties,
  DragEventHandler,
  FC,
  MouseEvent,
  useEffect,
  useRef,
  useState
} from "react"
import SimpleBar from "simplebar-react"
import { useToggle } from "../../../../../../../common/hooks"
import { useAppDispatch, useAppSelector } from "../../../../../../../store/store"
import { ConfirmModal, MouseHint } from "../../../../../../system/components"
import styles from "../../../CardSortingStyles.module.css"
import { cardSortingSlice } from "../../../reducers/cardSortingReducer/cardSortingReducer"
import {
  Group as GroupType,
  SortStage
} from "../../../reducers/cardSortingReducer/cardSortingReducerTypes"
import { useTranslate } from "../../../translates/useTranslate"
import { countRatedCards } from "../../../utils"
import { ArrowBackIcon } from "../../icons"
import { EyeIcon } from "../../icons/EyeIcon"
import { CardInGroup } from "../CardInGroup"
import quadrantStyles from "./QuadrantGroupStyles.module.css"
import "./ScrollbarStyles.css"
import { UnwrappedGroup } from "./UnwrappedGroup"

interface GroupProps {
  group: GroupType
  quadrantGroupColor?: string
  style?: CSSProperties
  isDesktopCollapsed: boolean
  cursorAwayHandler?: () => void
  userGroupColorMap?: Map<number, string>
}

const wrappedGroupHeightRem = 4.9
const wrappedQuadrantGroupHeightRem = 9.8
const unwrappedGroupHeightRem = 20.12
const groupWidthRem = 12.7

export const Group: FC<GroupProps> = props => {
  const { id, name, cards } = props.group

  const translates = useTranslate()
  const dispatch = useAppDispatch()

  const {
    renameGroup,
    deleteGroup,
    movePickedCardsToGroup,
    startDraggingGroup,
    replaceDraggingGroup,
    endDragging
  } = cardSortingSlice.actions

  const [hintEvent, setHintEvent] = useState<MouseEvent<
    HTMLDivElement | HTMLTextAreaElement
  > | null>()
  const [isNameHintShow, setIsNameHintShow] = useState(false)
  const [currentHint, setCurrentHint] = useState<"delete" | "name" | "like all" | "dislike all">(
    "delete"
  )
  const [isGroupUnwrapped, setIsGroupUnwrapped] = useState(false)
  const [isGroupDragging, setIsGroupDragging] = useState(false)

  const deleteModal = useToggle(false)
  const isAnyCardPicked =
    useAppSelector(state => state.cardSortingReducer.group_state.pickedCards.cards).length !== 0
  const state = useAppSelector(state => state.cardSortingReducer.group_state)
  const sortStage = useAppSelector(state => state.cardSortingReducer.sortStage)

  const groupRef = useRef<HTMLDivElement>(null)
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (textareaRef.current) {
      if (textareaRef.current.scrollHeight > textareaRef.current.clientHeight) {
        setIsNameHintShow(true)
      } else {
        setIsNameHintShow(false)
      }
    }
  }, [name])

  const groupClickHandler = () => dispatch(movePickedCardsToGroup(id))
  const ratedCardCounter = countRatedCards(cards)
  const isAllRated = ratedCardCounter === cards.length && cards.length !== 0
  const isDragging = isGroupDragging && !props.cursorAwayHandler

  const wrapBackGroup = () => {
    setIsGroupUnwrapped(false)
  }

  const handleDragStart = () => dispatch(startDraggingGroup(id))

  const handleDragEnd = () => dispatch(endDragging())

  const handleDragEnter: DragEventHandler<HTMLDivElement> = e => {
    if (id !== state.draggingGroupId) {
      dispatch(replaceDraggingGroup(id))
    }
    setIsGroupDragging(false)
  }

  return (
    <div
      className={
        sortStage === SortStage.QUADRANT_SORT ? quadrantStyles.quadrantGroup : styles.group
      }
      style={{
        opacity: state.draggingGroupId === id ? 0.3 : 1,
        cursor: isAnyCardPicked ? "pointer" : "unset",
        height: props.isDesktopCollapsed
          ? sortStage === SortStage.QUADRANT_SORT
            // ? `${wrappedQuadrantGroupHeightRem}rem`
            ? `49%`
            : `${wrappedGroupHeightRem}rem`
          : `${unwrappedGroupHeightRem}rem`,
        width: `${groupWidthRem}rem`,
        ...props.style
      }}
      onClick={groupClickHandler}
      onMouseLeave={props.cursorAwayHandler}
      ref={groupRef}
      draggable={isDragging}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragEnter={handleDragEnter}
    >
      {isGroupUnwrapped && (
        <UnwrappedGroup
          group={props.group}
          cursorAwayHandler={wrapBackGroup}
          clientX={groupRef.current ? groupRef.current.getBoundingClientRect().x : 0}
          clientY={groupRef.current ? groupRef.current.getBoundingClientRect().y : 0}
          heightDeltaRem={unwrappedGroupHeightRem - wrappedGroupHeightRem}
        />
      )}

      {deleteModal.state && (
        <ConfirmModal
          onConfirm={() => dispatch(deleteGroup(id))}
          onCancel={deleteModal.toggleValue}
          confirmText={translates.yes}
          cancelText={translates.no}
        >
          {translates.confirmDeleteGroup}
        </ConfirmModal>
      )}

      {hintEvent && currentHint === "delete" && (
        <MouseHint event={hintEvent}>{translates.deleteGroupHint}</MouseHint>
      )}
      {hintEvent && isNameHintShow && currentHint === "name" && (
        <MouseHint event={hintEvent}>{name}</MouseHint>
      )}

      <div
        className={styles.grabHandler}
        style={{
          cursor: isAnyCardPicked ? "pointer" : "grab",
          backgroundColor: props.quadrantGroupColor ? props.quadrantGroupColor : "white"
        }}
        onMouseDown={() => setIsGroupDragging(true)}
        onMouseUp={() => setIsGroupDragging(false)}
      >
        <div />
        <div />
        <div />
      </div>

      <div className={styles.groupInfo}>
        <div className={styles.groupHeadingContainer}>
          {sortStage === SortStage.MANUAL_SORT && (
            <textarea
              onMouseEnter={() => setCurrentHint("name")}
              onMouseMove={setHintEvent}
              onMouseLeave={() => {
                setHintEvent(null)
                setCurrentHint("delete")
              }}
              ref={textareaRef}
              value={name}
              onChange={e => dispatch(renameGroup({ id, value: e.target.value }))}
              className={styles.groupHeading}
              style={{ cursor: isAnyCardPicked ? "pointer" : "unset" }}
            />
          )}
          {sortStage === SortStage.QUADRANT_SORT && <p className={styles.groupHeading}>{name}</p>}
          {sortStage === SortStage.MANUAL_SORT && (
            <div
              onMouseMove={e => setHintEvent(e)}
              onMouseLeave={() => setHintEvent(null)}
              className={styles.cursorPointer}
              onClick={deleteModal.toggleValue}
            >
              <ArrowBackIcon className={styles.arrowBack} />
            </div>
          )}
        </div>

        <div className={styles.countersContainer}>
          <div className={classNames(styles.counterContainer, styles.commonCounterContainer)}>
            <span className={styles.commonCountNumber}>
              {cards.length} {translates.groupCardCounter}
            </span>
          </div>

          {sortStage === SortStage.MANUAL_SORT && (
            <div
              className={classNames(
                styles.counterContainer,
                isAllRated && styles.completedCounterContainer
              )}
            >
              <span
                className={classNames(
                  styles.countNumber,
                  isAllRated && styles.completedCountNumber
                )}
              >
                {ratedCardCounter} {translates.groupRatedCardCounter}
              </span>
            </div>
          )}
          {props.isDesktopCollapsed && (
            <EyeIcon
              styles={{ marginLeft: "auto" }}
              onMouseEnter={() => setIsGroupUnwrapped(true)}
            />
          )}
        </div>
      </div>

      <div className={classNames(styles.groupCardsScrollableContainer, "groupThinScrollbar")}>
        <SimpleBar
          style={{
            maxHeight: "100%",
            width: "100%",
            paddingBottom: "0.05rem",
            paddingRight: "0.3rem",
            paddingLeft: "0.3rem"
          }}
          autoHide={false}
        >
          <div className={styles.groupCardContainer}>
            {cards.map(card => (
              <CardInGroup
                key={card.id}
                card={card}
                groupId={id}
                // TODO починить
                // @ts-ignore
                color={props.userGroupColorMap && props.userGroupColorMap.get(card.group)}
              />
            ))}
          </div>
        </SimpleBar>
      </div>
    </div>
  )
}
