import { useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import {
  getErrorUrl,
  getObjectFromLocalStorage,
  saveObjectInLocalStorage
} from "../../../../../common/utils"
import { useAppSelector } from "../../../../../store/store"
import { InstructionBlock } from "../../../../system/components"
import { saveSorting, saveSortingJSON } from "../http"
import {
  CardSortingStateInfo,
  QuadrantSortingStateInfo,
  SortStage
} from "../reducers/cardSortingReducer/cardSortingReducerTypes"
import { useTranslate } from "../translates/useTranslate"
import { userGroupColors } from "../utils"
import { DesktopSorting } from "./DesktopSorting/DesktopSorting"
import InstructionSlider from "./InstructionSlider/InstructionSlider"
import { MobileSorting } from "./MobileSorting/MobileSorting"
import { PreloadBar } from "../../../../system/UX/PreloadBar/PreloadBar"

//TODO: придумать лучшую реализацию локал стораджа
export const Sorting = () => {
  const navigate = useNavigate()
  const [isInstructionsHide, setIsInstructionsHide] = useState(true)
  const [isSliderHide, setIsSliderHide] = useState(true)
  const translates = useTranslate()
  const isMobile = useAppSelector(state => state.appReducer.isMobile)
  const { info, quadrant_sorting_info, sortStage, user_groups, idea_groups } = useAppSelector(
    state => state.cardSortingReducer
  )
  const project_id = Number(useParams().project_id)
  const uploadTempStateIntervalRef = useRef()
  const uploadJSONTempStateIntervalRef = useRef()

  const [sortInfo, setSortInfo] = useState<CardSortingStateInfo | QuadrantSortingStateInfo>()

  const userGroupColorMap = new Map()
  let colorIndex = 0
  let bankGroups = user_groups || idea_groups
  bankGroups &&
    bankGroups.forEach(group => {
      userGroupColorMap.set(group.id, userGroupColors[colorIndex])
      colorIndex = colorIndex + 1 >= userGroupColors.length ? 0 : colorIndex + 1
    })

  useEffect(() => {
    if (sortStage === SortStage.QUADRANT_SORT && quadrant_sorting_info) {
      setSortInfo(quadrant_sorting_info)
    } else if (sortStage === SortStage.MANUAL_SORT) {
      setSortInfo(info)
    }
  }, [quadrant_sorting_info, info, sortStage])

  useEffect(() => {
    const projectDataObject = getObjectFromLocalStorage(String(project_id))

    if (projectDataObject.isSortingInstructionConfirmed) {
      setIsInstructionsHide(true)
    } else {
      setIsInstructionsHide(false)
    }

    // @ts-ignore
    uploadTempStateIntervalRef.current = setInterval(() => {
      saveSorting(project_id, false, sortStage)
        .then(() => console.log("Sorting state saved"))
        .catch(e => {
          console.log(e.message)
          navigate(getErrorUrl(e.message))
        })
    }, 1000 * 60 * 3)

    // @ts-ignore
    uploadJSONTempStateIntervalRef.current = setInterval(() => {
      saveSortingJSON(project_id)
        .then(() => console.log("JSON sorting state saved"))
        .catch(e => {
          console.log(e.message)
          navigate(getErrorUrl(e.message))
        })
    }, 1000 * 30)

    return () => {
      clearTimeout(uploadTempStateIntervalRef.current)
      uploadTempStateIntervalRef.current = undefined

      clearTimeout(uploadJSONTempStateIntervalRef.current)
      uploadJSONTempStateIntervalRef.current = undefined
    }
  }, [])

  const closeInstructionHandler = () => {
    setIsInstructionsHide(true)

    const projectDataObject = getObjectFromLocalStorage(String(project_id))
    saveObjectInLocalStorage(String(project_id), {
      ...projectDataObject,
      isSortingInstructionConfirmed: true
    })
  }

  const instructionBlock = sortInfo && (
    <InstructionBlock
      title={sortInfo.sorting_instruction_title}
      content={
        isMobile ? sortInfo.mobile_sorting_instruction_text : sortInfo.sorting_instruction_text
      }
      confirm={{
        handler: () => closeInstructionHandler(),
        confirmationText: translates.gotItButton
      }}
    />
  )

  const instructionSlider = <InstructionSlider onClick={closeInstructionHandler} />

  return (
    <>
      {!sortInfo ? (
        <PreloadBar isFullPage={true} />
      ) : (
        <>
          {!isInstructionsHide && <>{instructionBlock}</>}
          {isInstructionsHide && isMobile && (
            <MobileSorting
              showInstruction={() => setIsInstructionsHide(false)}
              userGroupColorMap={userGroupColorMap}
            />
          )}
          {isInstructionsHide && !isMobile && (
            <DesktopSorting
              showInstruction={() => setIsInstructionsHide(false)}
              userGroupColorMap={userGroupColorMap}
            />
          )}
        </>
      )}
    </>
  )
}
