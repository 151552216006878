import { DashboardScatterGroup } from "../../../../../../pages/DashboardPage/types/types"

export interface DashboardScatterGroupDemo extends DashboardScatterGroup {
  sizeRatio: {
    height: number
    width: number
  }
}

export const quadrantGroupColorsDemo = new Map([
  [0, "#f4ebec"],
  [1, "#fbfbfb"],
  [2, "#fceec9"],
  [3, "#fbfbfb"],
  [4, "#fbfbfb"],
  [5, "#fbfbfb"],
  [6, "#e8f8f5"],
  [7, "#fbfbfb"],
  [8, "#b4e1da"]
])

export const quadrantGroupTextColorsDemo = new Map([
  [0, "#ec784b"],
  [2, "#f7c200"],
  [4, "#a9a9a9"],
  [6, "#55d0c2"],
  [8, "#168868"]
])

const big = 5 / 2
const small = 5

export const groups: DashboardScatterGroupDemo[] = [
  {
    index: 0,
    name: "Facultative issues outside the project scope",
    sizeRatio: { height: big, width: big }
  },
  { index: 1, name: "", sizeRatio: { height: big, width: small } },
  { index: 2, name: "Lateral focus of the project", sizeRatio: { height: big, width: big } },
  { index: 3, name: "", sizeRatio: { height: small, width: big } },
  { index: 4, name: "Low agreement between experts", sizeRatio: { height: small, width: small } },
  { index: 5, name: "", sizeRatio: { height: small, width: big } },
  {
    index: 6,
    name: "Critical issues outside the project scope",
    sizeRatio: { height: big, width: big }
  },
  { index: 7, name: "", sizeRatio: { height: big, width: small } },
  { index: 8, name: "Main focus on the project", sizeRatio: { height: big, width: big } }
]
