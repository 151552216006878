import { MapTranslates } from "./translates"

const mapToDisplayTranslates = {
  all: "Общая",
  clusters: "По подразделениям"
}

const mapGraphWidgetTranslates =  {
  groups: "Карта идей, раскрашенная по общим трендам",
  grades: "Карта идей, раскрашенная по результатам голосования"
}

const clusterMapToDisplayTranslates = {
  groups: "По трендам", 
  grades: "По голосованию"
}

const clusterLegendTranslates = {
  groups: "Общие тренды:", 
  grades: "Результаты голосования:"
}

const mapInstructionTranslates = {
  spot: {
    name: "Пятно",
    description: "Один из ответов/идей/решений/предложений",
  },
  size: {
    name: "Размер пятна",
    description: "Большие пятна - это ключевые идеи с высокой степенью влияния",
  },
  gravity: {
    name: "Притяжение",
    description: "Чем ближе пятна друг к другу, тем больше эти идеи похожи друг на друга",
  },
  groupsColors: {
    name: "Цвета по темам",
    description: "Единые тренды обозначены одним цветом",
  },
  gradesColors : {
    name: "Цвета согласованности",
    description: "Показывают, что уже можно брать в работу, а где требуются уточнения",
  }
}

const mapFullscreenGraphTranslates = {
  back: "Назад",
  groups: "Карта идей, раскрашенная по общим трендам",
  grades: "Карта идей, раскрашенная по результатам голосования"
}

export const mapTranslates: MapTranslates = {
  mapToDisplay: mapToDisplayTranslates,
  clusterMapToDisplay: clusterMapToDisplayTranslates,
  graphWidget: mapGraphWidgetTranslates,
  instruction: mapInstructionTranslates,
  clusterLegend: clusterLegendTranslates,
  fullscreenGraph: mapFullscreenGraphTranslates
}
