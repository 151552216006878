import { FC } from "react"
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"
import { useToggle } from "../../../../../../common/hooks"
import { MobileButton, MobileModal } from "../../../../../system/UX/mobile"
import { useFindingUngradedCards } from "../../hooks/useFindingUngradedCards"
import { GradeStatus } from "../../reducers/cardSortingReducer/cardSortingReducerTypes"
import { useTranslate } from "../../translates/useTranslate"
import { MobileWarningIcon } from "../icons/MobileWarningIcon"
import { MobileCard } from "./MobileCard"
import styles from "./MobileSortingStyles.module.css"

interface MobileLikeModalProps {
  onConfirm: (isPending: { state: boolean; toggleValue: () => void }, noGrades?: boolean) => void
  onClose: () => void
}

export const MobileLikeModal: FC<MobileLikeModalProps> = ({ onClose, onConfirm }) => {
  const translates = useTranslate()
  const isPending = useToggle(false)
  const { ungradedCards, isAllCardsGraded, setUngradedCards } = useFindingUngradedCards()

  const setGradeForUngradedCard = (id: number, grade: GradeStatus) => {
    setUngradedCards(
      ungradedCards.map(card => {
        if (card.id == id) {
          if (card.grade_status === grade) return { ...card, grade_status: "unset" }
          return { ...card, grade_status: grade }
        }
        return card
      })
    )
  }

  return (
    <MobileModal onClose={onClose} isCollapsible={true}>
      <div className={styles.likeModalContainer}>
        <div className={styles.likeModalHeader}>
          <MobileWarningIcon />
          <h2 className={styles.likeModalHeading}>{translates.mobileTranslates.rateTheCards}</h2>
        </div>
        <p className={styles.almostDoneP}>{translates.mobileTranslates.YouAreAlmostDone}</p>
        <SimpleBar
          style={{ maxHeight: "24rem", paddingBottom: "0.05rem" }}
          autoHide={false}
          className="thinScrollbar"
        >
          <div className={styles.likeModalGroupContainer}>
            {ungradedCards.map(card => (
              <MobileCard
                key={card.id}
                id={card.id}
                text={card.text}
                grade_status={card.grade_status}
                setGradeForUngradedCard={setGradeForUngradedCard}
                place="like modal"
              />
            ))}
          </div>
        </SimpleBar>
        <MobileButton
          className={styles.likeModalSubmit}
          disabled={!isAllCardsGraded || isPending.state}
          onClick={() => onConfirm(isPending)}
        />
        <p>{translates.mobileTranslates.saveNoGrades}</p>
        <MobileButton
          className={styles.likeModalSubmit}
          disabled={isPending.state}
          onClick={() => onConfirm(isPending, true)}
        />
      </div>
    </MobileModal>
  )
}
