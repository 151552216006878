import { useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useToggle } from "../../../../../../common/hooks"
import { getErrorUrl, removeFromLocalStorage } from "../../../../../../common/utils"
import { useAppDispatch, useAppSelector } from "../../../../../../store/store"
import { Button, InfoBlock } from "../../../../../system/UX"
import { AlertModal, ConfirmModal } from "../../../../../system/components"
import styles from "../../CardSortingStyles.module.css"
import { saveSorting } from "../../http"
import { cardSortingSlice } from "../../reducers/cardSortingReducer/cardSortingReducer"
import { useTranslate } from "../../translates/useTranslate"
import { checkSortingErrors, countLikedAndDislikedCards } from "../../utils"
import { SimpleArrow } from "../icons/SimpleArrow"
import { LikesModal } from "./LikesModal"

export const BottomControls = () => {
  const translates = useTranslate()
  const [isHide, setIsHide] = useState(true)
  const [error, setError] = useState("")
  const wrapperRef = useRef<HTMLDivElement>(null)
  const finishSort = cardSortingSlice.actions.finishSort
  const { group_state, info, sortStage } = useAppSelector(state => state.cardSortingReducer)
  const isPending = useToggle(false)
  const likeModal = useToggle(false)
  const confirmModal = useToggle(false)
  const project_id = Number(useParams().project_id)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    setIsHide(false)

    const handleClickOutside = (event: MouseEvent) => {
      // @ts-ignore
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsHide(true)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const submitSorting = (
    isPending: { state: boolean; toggleValue: () => void },
    noGrades?: boolean
  ) => {
    isPending.toggleValue()
    saveSorting(project_id, true, sortStage, noGrades)
      .then(() => {
        isPending.toggleValue()
        confirmModal.toggleValue()
        dispatch(finishSort())
        removeFromLocalStorage(String(project_id))
      })
      .catch(e => navigate(getErrorUrl(e.message)))
  }

  const { likeCounter, dislikeCounter } = countLikedAndDislikedCards(group_state)

  const checkSortingErrorHandler = () => {
    checkSortingErrors({
      sortStage,
      errorSetter: setError,
      groupState: group_state,
      translates,
      info,
      likeCounter,
      dislikeCounter,
      likeModal,
      confirmModal
    })
  }

  return (
    <div className={styles.bottomInstruction} ref={wrapperRef}>
      {error && (
        <AlertModal onSubmit={() => setError("")} submitText={translates.gotItButton}>
          {error}
        </AlertModal>
      )}

      {likeModal.state && <LikesModal onConfirm={submitSorting} onCLose={likeModal.toggleValue} />}

      {confirmModal.state && (
        <ConfirmModal
          onConfirm={() => submitSorting(isPending)}
          onCancel={confirmModal.toggleValue}
          isPending={isPending.state}
          confirmText={translates.yes}
          cancelText={translates.no}
        >
          {translates.saveSortingConfirm}
        </ConfirmModal>
      )}

      <div className={styles.bottomControls}>
        <div className={styles.hideInstructionButton} onClick={() => setIsHide(!isHide)}>
          <SimpleArrow isTop={isHide} />
        </div>
        <Button
          variant="outlined"
          onClick={checkSortingErrorHandler}
          style={{
            position: "relative",
            top: isHide ? 0 : "11rem",
            transition: "top .2s"
          }}
        >
          {translates.finishSortButton}
        </Button>
      </div>
      <div className={styles.instructions} style={{ height: isHide ? 0 : "11rem" }}>
        <div className={styles.instructionsContainer}>
          <InfoBlock header={translates.instructionSteps.firstStep}>
            <p
              className={styles.bottomHintTextStyles}
              style={{ width: "8rem" }}
              dangerouslySetInnerHTML={{ __html: translates.instructionSteps.firstStepText }}
            />
          </InfoBlock>
          <InfoBlock header={translates.instructionSteps.secondStep}>
            <p
              className={styles.bottomHintTextStyles}
              style={{ width: "7rem" }}
              dangerouslySetInnerHTML={{ __html: translates.instructionSteps.secondStepText }}
            />
          </InfoBlock>
          <InfoBlock header={translates.instructionSteps.thirdStep}>
            <p
              className={styles.bottomHintTextStyles}
              style={{ width: "7.5rem" }}
              dangerouslySetInnerHTML={{ __html: translates.instructionSteps.thirdStepFirstText }}
            />
            <p
              className={styles.bottomHintTextStyles}
              style={{ width: "7rem" }}
              dangerouslySetInnerHTML={{ __html: translates.instructionSteps.thirdStepSecondText }}
            />
          </InfoBlock>
          <InfoBlock header={translates.instructionSteps.repeatSecondThirdSteps}>
            <p
              className={styles.bottomHintTextStyles}
              style={{ width: "12rem" }}
              dangerouslySetInnerHTML={{
                __html: translates.instructionSteps.repeatSecondThirdStepsFirstText
              }}
            />
            <p
              className={styles.bottomHintTextStyles}
              style={{ width: "14rem" }}
              dangerouslySetInnerHTML={{
                __html: translates.instructionSteps.repeatSecondThirdStepsSecondText
              }}
            />
            <p
              className={styles.bottomHintTextStyles}
              style={{ width: "8rem" }}
              dangerouslySetInnerHTML={{
                __html: translates.instructionSteps.repeatSecondThirdStepsThirdText
              }}
            />
          </InfoBlock>
          <InfoBlock header={translates.instructionSteps.fourthStep}>
            <p
              className={styles.bottomHintTextStyles}
              style={{ width: "11rem" }}
              dangerouslySetInnerHTML={{ __html: info.last_step_instruction_text }}
            />
          </InfoBlock>
        </div>
      </div>
    </div>
  )
}
