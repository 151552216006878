import _ from "lodash"
import { Dispatch, forwardRef, MutableRefObject, SetStateAction, useMemo } from "react"
import { useOutletContext } from "react-router-dom"
import {
  ContextType,
  GraphologyIdeaGroup,
  GraphRefs,
  LegendStyles
} from "../../../../../../pages/DashboardPage/types/types"
import { Legend } from "../../../../../system/components/Legend/Legend"
import { quadrantGroupTextColorsDemo } from "../../../ScatterChart/components/Scatter/DemoScatterConstants"
import { MapGraphWidgetTranslates } from "../../translates/translates"
import { Graph } from "../Graph/Graph"
import styles from "./GraphWidgetStyles.module.css"
import { quadrantLegend } from "../demoConstants"

const quadrantGroupColorsDemo = new Map([
  [0, "#da4052"],
  [1, "#939393"],
  [2, "#fccc47"],
  [3, "#939393"],
  [4, "#939393"],
  [5, "#939393"],
  [6, "#67a499"],
  [7, "#939393"],
  [8, "#088472"]
])

interface GraphWidgetProps {
  type: "grades" | "groups"
  legend: {
    styles: LegendStyles
    filterSetter: Dispatch<SetStateAction<number[]>>
  }
  filters: {
    groups: number[]
    grades: number[]
  }
  fullscreenControl: Dispatch<SetStateAction<string>>
  graphRefs: MutableRefObject<GraphRefs>
  // TODO temporapy
  nodeSize: "absolute" | "percentile"
  translates: MapGraphWidgetTranslates
}

function RefGraphWidget(props: GraphWidgetProps) {
  const { graphRefs, type, legend, filters, fullscreenControl, nodeSize, translates } = props
  const { ideaGroups, graphData } = useOutletContext<ContextType>()

  // const quadrantLegend: GraphologyIdeaGroup[] = useMemo(
  //   () => [
  //     {
  //       id: 8,
  //       name: "Main focus of the project",
  //       color: quadrantGroupTextColorsDemo.get(8)
  //     },
  //     {
  //       id: 2,
  //       name: "Lateral focus of the project",
  //       color: quadrantGroupTextColorsDemo.get(2)
  //     },
  //     {
  //       id: 6,
  //       name: "Critical issues outside the project scope",
  //       color: quadrantGroupTextColorsDemo.get(6)
  //     },
  //     {
  //       id: 0,
  //       name: "Facultative issues outside the project scope",
  //       color: quadrantGroupTextColorsDemo.get(0)
  //     },
  //     {
  //       id: 4,
  //       name: "Low agreement between experts",
  //       color: quadrantGroupTextColorsDemo.get(4)
  //     }
  //   ],
  //   []
  // )

  return (
    <div className={styles.graphWidget}>
      {/* TODO translates */}
      {type === "groups" && <p style={{ textAlign: "start" }}>{translates.groups}</p>}
      {type === "grades" && <p style={{ textAlign: "end" }}>{translates.grades}</p>}
      <div className={styles.graphWidgetLegend}>
        {type === "groups" && ideaGroups && (
          <Legend items={ideaGroups} setter={legend.filterSetter} CSSstyles={legend.styles} />
        )}
        <Graph
          graphData={_.cloneDeep(graphData)}
          coloring={type}
          fullscreenControl={() => fullscreenControl(`all-${type}`)}
          ref={ref => (graphRefs.current[`all-${type}`] = ref)}
          filterGroup={filters.groups}
          filterGrade={filters.grades}
          nodeSize={nodeSize}
        />
        {type === "grades" && (
          <Legend items={quadrantLegend} setter={legend.filterSetter} CSSstyles={legend.styles} />
        )}
      </div>
    </div>
  )
}

export const GraphWidget = forwardRef(RefGraphWidget)
