import { FormEventHandler, useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useToggle } from "../../../../common/hooks"
import { Pages } from "../../../../common/types"
import { getErrorUrl, readJwtPayload } from "../../../../common/utils"
import { getLanguage } from "../../../../store/appReducer/ActionCreators"
import { appSlice } from "../../../../store/appReducer/appReducer"
import { UserRole } from "../../../../store/appReducer/appReducerTypes"
import { useAppDispatch } from "../../../../store/store"
import { AlertModal } from "../../../system/components"
import { Button, Input } from "../../../system/UX"
import styles from "./AuthStyles.module.css"
import { checkUser, registerUser } from "./http"
import { useTranslate } from "./translates/useTranslate"

export const Auth = () => {
  const location = useLocation()
  const token = String(useParams().token)
  const project_id = Number(useParams().project_id)
  const dispatch = useAppDispatch()
  const { setUser, setLanguage } = appSlice.actions
  const translate = useTranslate()
  const [name, setName] = useState("")
  const modal = useToggle(false)
  const isPending = useToggle(false)
  const [isCheckUserPending, setIsCheckPending] = useState(true)
  const navigate = useNavigate()

  const pathPattern = useMemo(() => /^\/auth\/dashboard\/[\w.]+\/?[\d]+$/, [])


  const setTokenAndNavigateToWork = (data: string) => {
    const payload = readJwtPayload(data)
    const oldToken = localStorage.getItem("token")

    if (oldToken) {
      const oldTokenData = readJwtPayload(oldToken)
      if (oldTokenData.sub !== payload.sub) {
        localStorage.clear()
      }
    }

    localStorage.setItem("token", data)

    dispatch(
      setUser({
        id: payload.id,
        name: payload.name,
        token: payload.sub,
        email: payload.email,
        user_role: payload.user_role,
        client_id: payload.client_id
      })
    )

    if (payload.user_role !== UserRole.SUPERADMIN) dispatch(getLanguage())

    if (pathPattern.test(location.pathname)) {
      navigate(`${Pages.dashboard}/${project_id}`)
      return
    }

    if (payload.user_role === UserRole.SUPERADMIN) {
      navigate(Pages.superadmin_page)
    } else if (payload.user_role === UserRole.ADMIN && !project_id) {
      navigate(`${Pages.admin}/${payload.client_id}`)
    } else {
      navigate(`${Pages.main}/${project_id}`)
    }

  }

  const submit: FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault()

    if (name) {
      isPending.toggleValue()
      registerUser(token, name)
        .then(setTokenAndNavigateToWork)
        .catch(e => navigate(getErrorUrl(e.message)))
    } else {
      modal.toggleValue()
    }
  }

  useEffect(() => {
    checkUser(token)
      .then(data => {
        if (data === "RU" || data === "EN") {
          dispatch(setLanguage(data))
          setIsCheckPending(false)
        } else {
          setTokenAndNavigateToWork(data)
        }
      })
      .catch(e => navigate(getErrorUrl(e.message)))
  }, [])

  return (
    <>
      {modal.state && (
        <AlertModal onSubmit={modal.toggleValue} submitText={translate.gotItInputError}>
          {translate.textInputError}
        </AlertModal>
      )}
      {!isCheckUserPending && (
        <form className={styles.authBlock} onSubmit={submit}>
          <Input
            value={name}
            onChange={e => setName(e.target.value)}
            placeholder={translate.askNamePlaceholder}
            label={translate.askNameTittle}
            labelClassName={styles.label}
            containerClassName={styles.inputContainer}
          />
          <Button type="submit" isPending={isPending.state}>
            {translate.send}
          </Button>
        </form>
      )}
    </>
  )
}
