import Plot from "react-plotly.js"
import { useOutletContext } from "react-router-dom"
import { ContextType } from "../../../../../../pages/DashboardPage/types/types"
import styles from "./UserChartStyles.module.css"
import { useMemo } from "react"
import { Data } from "plotly.js"
import { CountryColors } from "../../../../../../pages/DashboardPage/types/constants"
import { useAppSelector } from "../../../../../../store/store"
import { PreloadBar } from "../../../../../system/UX/PreloadBar/PreloadBar"

interface UserChartProps {}

export function UserChart(props: UserChartProps) {
  const { userData, clusters, countries } = useOutletContext<ContextType>()
  const remValue = useAppSelector(state => state.appReducer.remValue)

  const data: Data[] | undefined = useMemo(() => {
    if (userData && clusters && countries) {
      const sortedClusters = clusters?.sort(
        (a, b) =>
          userData.filter(user => user.cluster === b.id).length -
          userData.filter(user => user.cluster === a.id).length
      )
      const sortedCountries = countries.sort(
        (a, b) =>
          userData.filter(user => user.country === b.id).length -
          userData.filter(user => user.country === a.id).length
      )
      return sortedCountries.map((country, index) => {
        const yValues = sortedClusters.map(
          cluster =>
            userData
              .filter(user => user.country === country.id)
              .filter(user => user.cluster === cluster.id).length
        )
        const totalUsers = yValues.reduce((acc, value) => acc + value, 0)

        const xValues = sortedClusters.map(cluster => {
          if (cluster.name.includes("SME")) {
            return "SME"
          }
          return cluster.name
        })

        return {
          type: "bar",
          x: xValues,
          y: yValues,
          name: `${country.name} (${totalUsers} ptc.)`,
          hoverinfo: "none",
          text: yValues && yValues.map(String),
          textposition: "auto",
          marker: {
            color: CountryColors[index],
            opacity: 1
          }
        }
      })
    }
  }, [clusters, countries, userData])

  return (
    <div className={styles.userChartContainer}>
      {!data ? <PreloadBar isFullPage={true} /> : (
        <Plot
          data={data}
          style={{ width: "100%", height: "100%" }}
          layout={{
            // width: 600,
            // height: 450,
            barmode: "stack",
            margin: { t: remValue * 5, b: remValue, l: remValue * 2, r: remValue },
            xaxis: {
              tickangle: 0
            },
            legend: { traceorder: "normal" },
            title: {
              text: "Number of Participants by Type of <br>Organisation and Country",
              font: { size: remValue }
            }
          }}
          config={{displaylogo: false}}
        />
      )}
    </div>
  )
}
