import { useEffect } from "react"
import { useNavigate, useOutletContext, useParams } from "react-router-dom"
import { getErrorUrl } from "../../../common/utils"
import { ScatterChart } from "../../../project/modules/main/ScatterChart/ScatterChart"
import { PreloadBar } from "../../../project/system/UX"
import { DashboardHttp } from "../http"
import { ContextType, DashboardScatterOut } from "../types/types"
import _ from "lodash"

export function ScatterChartPage() {
  const { projectId } = useParams()
  const { scatterData, setScatterData } = useOutletContext<ContextType>()
  const navigate = useNavigate()

  useEffect(() => {
    if (projectId) {
      DashboardHttp.getScatterData(Number(projectId))
        .then(data => {
          const dataDemo = _.cloneDeep(data)
          const cardsDemo = dataDemo.cards.map(card => {
            if (card.x_coord <= -0.2 && card.y_coord <= -0.2) {
              return {...card, quadrant_group: 0}
            } else if (card.x_coord >= 0.2 && card.y_coord <= -0.2) {
              return {...card, quadrant_group: 2}
            } else if (card.x_coord <= -0.2 && card.y_coord >= 0.2) {
              return {...card, quadrant_group: 6}
            } else if (card.x_coord >= 0.2 && card.y_coord >= 0.2) {
              return {...card, quadrant_group: 8}
            } else {
              return {...card, quadrant_group: 4}
            }
          })
          setScatterData({...dataDemo, cards: cardsDemo})
        })
        .catch(error => navigate(getErrorUrl(error.message)))
    }
  }, [projectId])


  return scatterData ? <ScatterChart data={scatterData} /> : <PreloadBar isFullPage={true} />
}