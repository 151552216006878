import { DashboardRoute } from "../common/types"
import { MainDashboardPage } from "../pages/DashboardPage/pages/MainDashboardPage"
import { MapPage } from "../pages/DashboardPage/pages/MapPage"
import { ScatterChartPage } from "../pages/DashboardPage/pages/ScatterChartPage"


export const dashboardRoutes: DashboardRoute[] = [
  // {
  //   path: "results",
  //   element: ResultsPage
  // },
  {
    path: "map",
    element: MapPage
  },
  {
    path: "dashboard",
    element: MainDashboardPage
  },
  {
    path: "scatter",
    element: ScatterChartPage
  },
  // {
  //   path: "roles",
  //   element: RolesAndTasksPage
  // },
]
