import { useAppSelector } from "../store/store"

export function useTranslates<T>({ en, ru }: { en: T; ru: T }): T {
  const lang = useAppSelector(state => state.appReducer.projectInfo.language)

  switch (lang) {
    case "EN":
      return en
    case "RU":
      return ru
    default:
      return en
  }
}
