import styles from "./DemoScatterLabelsStyles.module.css"

interface ScatterLabelsProps {
  widthRem: number
  heightRem: number
}

export function DemoScatterLabels(props: ScatterLabelsProps) {
  const { widthRem, heightRem } = props

  return (
    <div
      style={{ width: `${widthRem}rem`, height: `${heightRem}rem` }}
      className={styles.scatterLabels}
    >
      <p
        style={{
          top: "50%",
          left: "-13%",
          rotate: "270deg",
          // transform: "translate(0, -50%)"
        }}
      >
        Project Scope
      </p>
      <p
        style={{
          top: "calc(100% + 1.5rem)",
          left: "50%",
          transform: "translate(-50%, 0)"
        }}
      >
        Criticality
      </p>
    </div>
  )
}
