import _ from "lodash"
import { useMemo, useRef, useState } from "react"
import { useOutletContext } from "react-router-dom"
import { useTranslates } from "../../../../common/useTranslates"
import { gradeCategories } from "../../../../pages/DashboardPage/types/constants"
import {
  ColoringType,
  ContextType,
  GraphRefs,
  GraphRefType,
  LegendStyles
} from "../../../../pages/DashboardPage/types/types"
import { IconButtonTemp } from "../../../UI/IconButtonTemp/IconButtonTemp"
import { RadioButton } from "../../../UI/RadioButton/RadioButton"
import { Legend } from "../../../system/components/Legend/Legend"
import styles from "./MapStyles.module.css"
import { FullscreenGraph } from "./components/FullscreenGraph/FullscreemGraph"
import { Graph } from "./components/Graph/Graph"
import { GraphWidget } from "./components/GraphWidget/GraphWidget"
import { Instruction } from "./components/Instruction/Instruction"
import { InfoIcon } from "./components/icons/InfoIcon"
import { mapTranslates as en } from "./translates/enTranslates"
import { mapTranslates as ru } from "./translates/ruTranslates"
import { MapTranslates } from "./translates/translates"

export function Map() {
  const [mapToDisplay, setMapToDisplay] = useState<"all" | "clusters">("all")
  const [clustersMapToDisplay, setClustersMapToDisplay] = useState<ColoringType>("groups")
  const [fullscreenGraph, setFullscreenGraph] = useState("")
  const [instruction, setInstruction] = useState(false)
  const [filterGroup, setFilterGroup] = useState<number[]>([])
  const [filterGrade, setFilterGrade] = useState<number[]>([])
  const { graphData, ideaGroups, clusters, question } = useOutletContext<ContextType>()
  const graphRefs = useRef<GraphRefs>({})
  const translates = useTranslates<MapTranslates>({ en: en, ru: ru })

  // TODO node size switch temporary
  const [nodeSize, setNodeSize] = useState<"absolute" | "percentile">("percentile")

  // TODO translates
  // TODO All and Clusters component
  const radioOptions: { name: "all" | "clusters"; label: string }[] = useMemo(
    () => [
      { name: "all", label: translates.mapToDisplay.all },
      { name: "clusters", label: translates.mapToDisplay.clusters }
    ],
    [translates]
  )

  const radioOptionsClusters: { name: ColoringType; label: string }[] = useMemo(
    () => [
      { name: "groups", label: translates.clusterMapToDisplay.groups },
      { name: "grades", label: translates.clusterMapToDisplay.grades }
    ],
    [translates]
  )

  const mapLegendStyles: LegendStyles = useMemo(
    () => ({
      container: styles.graphLegend,
      checked: styles.graphLegendRowActive,
      item: styles.graphLegendRow,
      itemHover: styles.graphLegendRowHover,
      iconSize: "1rem"
    }),
    []
  )

  function resetFilters() {
    setFilterGrade([])
    setFilterGroup([])
  }

  return (
    <div className={styles.mapContainer}>
      <div className={styles.mapButtonsContainer}>
        <div className={styles.mapButtonsRadio}>
          {clusters?.length !== 0 &&
            radioOptions.map(option => (
              <RadioButton
                key={option.name}
                name={option.name}
                label={option.label}
                checked={mapToDisplay === option.name}
                onClick={() => {
                  setMapToDisplay(option.name)
                  resetFilters()
                }}
              />
            ))}
          <IconButtonTemp onClick={() => setInstruction(true)}>
            <InfoIcon />
          </IconButtonTemp>
        </div>
        {/* {!(question.includes("well-being") || question.includes("organisation")) &&
          mapToDisplay === "clusters" && (
            <div className={styles.mapButtonsRadioClusters}>
              {clusters?.length !== 0 &&
                radioOptionsClusters.map(option => (
                  <RadioButton
                    key={option.name}
                    name={option.name}
                    label={option.label}
                    checked={clustersMapToDisplay === option.name}
                    onClick={() => {
                      setClustersMapToDisplay(option.name)
                    }}
                    CSSclasses={{
                      button: styles.mapRadioClustersButton
                    }}
                  />
                ))}
            </div>
          )} */}
        {/* <div className={styles.mapButtonsRadioClusters}>
          <RadioButton
            name={"absolute"}
            label={"Абсолютный"}
            checked={nodeSize === "absolute"}
            onClick={() => {
              setNodeSize("absolute")
            }}
            CSSclasses={{
              button: styles.mapRadioClustersButton
            }}
          />
          <RadioButton
            name={"percentile"}
            label={"Процентиль"}
            checked={nodeSize === "percentile"}
            onClick={() => {
              setNodeSize("percentile")
            }}
            CSSclasses={{
              button: styles.mapRadioClustersButton
            }}
          />
        </div> */}
        <div className={styles.mapButtonsDownloadContainer}>
          {/* <IconButtonTemp label=".png" className={styles.mapButtonsDownload}>
            <DownloadIcon />
          </IconButtonTemp>
          <IconButtonTemp label=".html" className={styles.mapButtonsDownload}>
            <DownloadIcon />
          </IconButtonTemp> */}
        </div>
      </div>
      {mapToDisplay === "all" && (
        <div className={styles.mapLayoutAll}>
          <GraphWidget
            graphRefs={graphRefs}
            type="groups"
            legend={{
              styles: mapLegendStyles,
              filterSetter: setFilterGroup
            }}
            filters={{ groups: filterGroup, grades: filterGrade }}
            fullscreenControl={setFullscreenGraph}
            nodeSize={nodeSize}
            translates={translates.graphWidget}
          />
          {!(question.includes("well-being") || question.includes("organisation")) && (
            <GraphWidget
              graphRefs={graphRefs}
              type="grades"
              legend={{
                styles: mapLegendStyles,
                filterSetter: setFilterGrade
              }}
              filters={{ groups: filterGroup, grades: filterGrade }}
              fullscreenControl={setFullscreenGraph}
              nodeSize={nodeSize}
              translates={translates.graphWidget}
            />
          )}
        </div>
      )}
      {mapToDisplay === "clusters" && (
        <div className={styles.mapLayoutClusters}>
          <div className={styles.mapClustersLegend}>
            <p>{translates.clusterLegend.groups}</p>
            {ideaGroups && (
              <Legend items={ideaGroups} setter={setFilterGroup} CSSstyles={mapLegendStyles} />
            )}
            {/* {!(question.includes("well-being") || question.includes("organisation")) && (
              <>
                <p>{translates.clusterLegend.grades}</p>
                {gradeCategories && (
                  <Legend
                    items={gradeCategories}
                    setter={setFilterGrade}
                    CSSstyles={mapLegendStyles}
                  />
                )}
              </>
            )} */}
          </div>
          <div className={styles.mapClusters}>
            {clusters?.map(cluster => (
              <div key={cluster.id} className={styles.mapClustersColumn}>
                <p>{cluster.name}</p>
                {clustersMapToDisplay === "groups" && (
                  <Graph
                    clusterId={cluster.id}
                    graphData={_.cloneDeep(graphData)}
                    coloring={"groups"}
                    sigmaClassName={styles.sigmaStyle}
                    fullscreenControl={() => setFullscreenGraph(`${cluster.id}-grades`)}
                    ref={ref => (graphRefs.current[`${cluster.id}-grades`] = ref)}
                    filterGroup={filterGroup}
                    filterGrade={filterGrade}
                    nodeSize={nodeSize}
                  />
                )}
                {clustersMapToDisplay === "grades" && (
                  <Graph
                    clusterId={cluster.id}
                    graphData={_.cloneDeep(graphData)}
                    coloring={"grades"}
                    sigmaClassName={styles.sigmaStyle}
                    fullscreenControl={() => setFullscreenGraph(`${cluster.id}-groups`)}
                    ref={ref => (graphRefs.current[`${cluster.id}-groups`] = ref)}
                    filterGroup={filterGroup}
                    filterGrade={filterGrade}
                    nodeSize={nodeSize}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      {fullscreenGraph && ideaGroups && graphRefs.current[fullscreenGraph] && (
        <FullscreenGraph
          fullscreenControl={() => setFullscreenGraph("")}
          ideaGroups={ideaGroups}
          gradeCategories={gradeCategories}
          legendStyles={mapLegendStyles}
          {...(graphRefs.current[fullscreenGraph] as GraphRefType)}
          translates={translates.fullscreenGraph}
        />
      )}
      <Instruction
        onClose={() => setInstruction(false)}
        active={instruction}
        translates={translates.instruction}
      />
    </div>
  )
}
