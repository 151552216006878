import classNames from "classnames"
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"
import { useAppSelector } from "../../../../../../store/store"
import styles from "../../CardSortingStyles.module.css"
import { useTranslate } from "../../translates/useTranslate"
import CardInBank from "./CardInBank"
import { CircleProgressBar } from "./CircleProgressBar"
import { QuadrantGroupTypeIcon } from "../icons/QuadrantGroupTypeIcon/QuadrantGroupTypeIcon"

interface BankOfCardsProps {
  userGroupColorMap?: Map<number, string>
}

export const BankOfCards = (props: BankOfCardsProps) => {
  const translate = useTranslate()
  const { bank, total_number_of_cards } = useAppSelector(
    state => state.cardSortingReducer.group_state
  )
  const {user_groups, idea_groups, project_name} = useAppSelector(state => state.cardSortingReducer)

  const { userGroupColorMap } = props

  return (
    <div className={styles.bankOfCardsWithCounter}>
      <div className={styles.bankCounter}>
        <div className={styles.counter}>
          <CircleProgressBar
            sizeRem={0.75}
            progress={(total_number_of_cards - bank.length) / total_number_of_cards}
          />
          <span className={styles.counterSpan}>
            {total_number_of_cards - bank.length}/{total_number_of_cards}{" "}
            {translate.mobileTranslates.sorted}
          </span>
        </div>
      </div>
      <div className={styles.bankOfCards}>
        {bank.length !== 0 && (
          <SimpleBar
            style={{ maxHeight: "100%", width: "100%", paddingBottom: "0.05rem" }}
            autoHide={false}
          >
            {user_groups && (
              <div className={styles.bankOrBinCardContainer}>
                {user_groups.map(group => (
                  <div className={styles.bankOrBinCardContainer} key={group.id}>
                    <div className={styles.bankGroup}>
                      <QuadrantGroupTypeIcon color={userGroupColorMap?.get(group.id)} />
                      <p>{group.name}</p>
                    </div>
                    {bank &&
                      bank.map(
                        card =>
                          card.group === group.id && (
                            <CardInBank
                              key={card.id}
                              card={card}
                              color={userGroupColorMap && userGroupColorMap.get(group.id)}
                            />
                          )
                      )}
                  </div>
                ))}
              </div>
            )}
            {!user_groups && !idea_groups && (
              <div className={styles.bankOrBinCardContainer}>
                {bank && bank.map(card => <CardInBank key={card.id} card={card} />)}
              </div>
            )}
            {!user_groups && idea_groups && (
              <div className={styles.bankOrBinCardContainer}>
                {idea_groups.map(group => (
                  <div className={styles.bankOrBinCardContainer} key={group.id}>
                    <div className={styles.bankGroup}>
                      <QuadrantGroupTypeIcon color={userGroupColorMap?.get(group.id)} />
                      <p>{group.name}</p>
                    </div>
                    {bank &&
                      bank.map(
                        card =>
                          card.idea_group === group.id && (
                            <CardInBank
                              key={card.id}
                              card={card}
                              color={userGroupColorMap && userGroupColorMap.get(group.id)}
                            />
                          )
                      )}
                  </div>
                ))}
              </div>
            )}
          </SimpleBar>
        )}
        {!bank.length && (
          <p className={classNames(styles.containerPlaceholder, styles.bankPlaceholder)}>
            {translate.bankPlaceholder}
          </p>
        )}
      </div>
    </div>
  )
}
