import { MainDashboardTranslates } from "./translates"

const mainDashboardInfoBlockTranslates = {
  ideas: "идей предложено",
  participants: "приняли участие",
  countries: "стран",
  efficient: "идей команда оценила как эффективные",
  hasLeader: "задач получили желающих лидировать",
  hasFollower: "задач получили желающих участвовать",
  averageFollower: "желающих участвовать в среднем на каждую задачу"
}

const mainDashboardCardsList = {
  header: "Рейтинг идей",
  originalSort: "По оригинальности",
  popularSort: "По популярности"
}

const mainDashboardUserListTranslates = {
  header: "Динамика команды",
  ideas: "Идеи",
  sort: "Сортировка",
  quadrantSort: "Оценка"
}

export const mainDashboardTranslates: MainDashboardTranslates = {
  infoBlock: mainDashboardInfoBlockTranslates,
  cardsList: mainDashboardCardsList,
  userList: mainDashboardUserListTranslates
}

