import { Settings } from "sigma/settings"
import { NodeDisplayData, PartialButFor } from "sigma/types"
import { splitToLines } from "../utils"

export default function drawLabel(
  context: CanvasRenderingContext2D,
  data: PartialButFor<NodeDisplayData, "x" | "y" | "size" | "label" | "color">,
  settings: Settings
): void {
  if (!data.label) return

  const size = data.labelSize || settings.labelSize
  const font = settings.labelFont
  const weight = settings.labelWeight
  const color = data.labelColor || settings.labelColor.color

  context.fillStyle = color
  context.font = `${weight} ${size}px ${font}`

  const maxWidthPx = Math.min(240, data.label.length * 8)

  const lines = splitToLines(context, data.label, maxWidthPx)
  const padding = 3

  // Draw each line of text
  lines.forEach((line, index) => {
    context.fillText(
      line,
      data.x + data.size + 8,
      data.y + size * (index + 1 - lines.length / 2) - padding
    )
  })
}
