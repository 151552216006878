// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GraphWidgetStyles_graphWidget__59IeT {\n  display: flex;\n  flex-direction: column;\n  flex: 1 1;\n  gap: 0.5rem;\n  height: 100%;\n}\n\n.GraphWidgetStyles_graphWidget__59IeT > p {\n  color: #585858;\n  font-size: 0.875rem;\n  font-weight: 700;\n  letter-spacing: 0.0175rem;\n}\n\n.GraphWidgetStyles_graphWidgetLegend__QPiSn {\n  display: flex;\n  flex-direction: row;\n  background-color: #F4F4F4;\n  border-radius: 0.5rem;\n  padding: 0.1rem;\n  justify-content: space-between;\n  height: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/project/modules/main/Map/components/GraphWidget/GraphWidgetStyles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAO;EACP,WAAW;EACX,YAAY;AACd;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,qBAAqB;EACrB,eAAe;EACf,8BAA8B;EAC9B,YAAY;AACd","sourcesContent":[".graphWidget {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  gap: 0.5rem;\n  height: 100%;\n}\n\n.graphWidget > p {\n  color: #585858;\n  font-size: 0.875rem;\n  font-weight: 700;\n  letter-spacing: 0.0175rem;\n}\n\n.graphWidgetLegend {\n  display: flex;\n  flex-direction: row;\n  background-color: #F4F4F4;\n  border-radius: 0.5rem;\n  padding: 0.1rem;\n  justify-content: space-between;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"graphWidget": "GraphWidgetStyles_graphWidget__59IeT",
	"graphWidgetLegend": "GraphWidgetStyles_graphWidgetLegend__QPiSn"
};
export default ___CSS_LOADER_EXPORT___;
