import { MouseEvent, useMemo, useState } from "react"
import { useOutletContext } from "react-router-dom"
import {
  ContextType,
  Coords,
  DashboardCardEssentials
} from "../../../../../../pages/DashboardPage/types/types"
import { DashboardCardHint } from "../../../../../system/components/DashboardCardHint/DashboardCardHint"
import styles from "./ScatterDotStyles.module.css"
import { getDarkerColor } from "../../../Map/components/Graph/utils"

interface ScatterDotProps {
  coords: Coords
  card: DashboardCardEssentials
  onClick: () => void
  hoveredCard: number | null
}

export function ScatterDot(props: ScatterDotProps) {
  const { coords, card, onClick, hoveredCard } = props
  const [hintEvent, setHintEvent] = useState<MouseEvent<HTMLElement> | null>()
  const { graphSettings } = useOutletContext<ContextType>()

  const settings = useMemo(
    () => graphSettings?.general_settings,
    [graphSettings?.general_settings]
  )

  return (
    <>
      {hintEvent && <DashboardCardHint event={hintEvent} card={card} />}
      {settings && (
        <div
          className={styles.scatterDot}
          onMouseOver={event => setHintEvent(event)}
          onMouseLeave={() => setHintEvent(null)}
          // onClick={() => onClick()}
          style={{
            top: coords.y_coord,
            left: coords.x_coord,
            ...(hoveredCard === card.id
              ? {
                  height: `${
                    settings.min_node_size +
                    card.pagerankPercentile * settings.node_size_factor * 3
                  }px`,
                  width: `${
                    settings.min_node_size +
                    card.pagerankPercentile * settings.node_size_factor * 3
                  }px`,
                  zIndex: 10,
                  outline: `2px ${getDarkerColor(card.ideaGroup.color)} solid`
                }
              : {
                  height: `${
                    settings.min_node_size + card.pagerankPercentile * settings.node_size_factor
                  }px`,
                  width: `${
                    settings.min_node_size + card.pagerankPercentile * settings.node_size_factor
                  }px`,
                  zIndex: 5,
                  ...(hoveredCard && {opacity: 0.1}),
                }),
            backgroundColor: `${card.ideaGroup.color}`,
            border: `${card.controversial && "1px solid black"}`
          }}
        />
      )}
    </>
  )
}
