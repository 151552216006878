import { FC } from "react"
import SimpleBar from "simplebar-react"
import { useToggle } from "../../../../../../common/hooks"
import { Portal } from "../../../../../system/service"
import { Button, Modal } from "../../../../../system/UX"
import styles from "../../CardSortingStyles.module.css"
import { useFindingUngradedCards } from "../../hooks/useFindingUngradedCards"
import { useTranslate } from "../../translates/useTranslate"
import CardInLikeModal from "./CardInLikeModal"

interface LikesModalProps {
  onConfirm: (isPending: { state: boolean; toggleValue: () => void }, noGrades?: boolean) => void
  onCLose: () => void
}

export const LikesModal: FC<LikesModalProps> = props => {
  const { onCLose, onConfirm } = props

  const isPending = useToggle(false)
  const translates = useTranslate()

  const { ungradedCards, isAllCardsGraded } = useFindingUngradedCards()

  return (
    <Portal>
      <Modal>
        <h3>{translates.setLikesRequest}</h3>
        <SimpleBar autoHide={false} className={styles.lakeModalScrollContainer}>
          <div className={styles.likeModalCardsContainer}>
            {ungradedCards.map(card => (
              <CardInLikeModal card={card} />
            ))}
          </div>
        </SimpleBar>
        <div className={styles.likesModalButtonContainer}>
          <Button onClick={onCLose}>Отмена</Button>
          <Button
            onClick={() => onConfirm(isPending)}
            disabled={!isAllCardsGraded}
            isPending={isPending.state}
          >
            Сохранить
          </Button>
          <Button onClick={() => onConfirm(isPending, true)} isPending={isPending.state}>
            {translates.saveNoGRades}
          </Button>
        </div>
      </Modal>
    </Portal>
  )
}
