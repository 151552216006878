import { useMemo, useState } from "react"
import { useOutletContext } from "react-router-dom"
import SimpleBar from "simplebar-react"
import { ContextType, DashboardScatterOut } from "../../../../pages/DashboardPage/types/types"
import { DashboardCard } from "../../../system/components/DashboardCard/DashboardCard"
import { Legend } from "../../../system/components/Legend/Legend"
import { groups, quadrantGroupColorsDemo } from "./components/Scatter/DemoScatterConstants"
import { Scatter } from "./components/Scatter/Scatter"
import styles from "./ScatterChartStyles.module.css"

interface ScatterChartProps {
  data: DashboardScatterOut
}

export function ScatterChart(props: ScatterChartProps) {
  const { data } = props
  // TODO 8 (always the best group)
  const [groupFilter, setGroupFilter] = useState<number[]>([])
  const [hoveredCard, setHoveredCard] = useState<number | null>(null)
  // const [pickedCardGroup, setPickedCardGroup] = useState<number>(3)
  const [pickedGroup, setPickedGroup] = useState<number>(8)
  const { cardsMap, ideaGroups } = useOutletContext<ContextType>()

  const scatterLegendStyles = useMemo(
    () => ({
      container: styles.scatterLegend,
      checked: styles.scatterLegendRowActive,
      item: styles.scatterLegendRow,
      itemHover: styles.scatterLegendHover,
      iconSize: "0.6rem"
    }),
    []
  )

  function onClick(quadrantGroupIndex: number) {
    if ([0, 2, 6, 8].includes(quadrantGroupIndex)) {
      setPickedGroup(quadrantGroupIndex)
    } else {
      setPickedGroup(4)
    }
  }

  const filteredScatterData = useMemo(() => {
    if (groupFilter.length !== 0) {
      return data.cards.filter(card => {
        const ideaGroup = cardsMap.get(String(card.id))?.ideaGroup.id
        return ideaGroup && groupFilter.includes(ideaGroup)
      })
    }
    return data.cards
  }, [cardsMap, data.cards, groupFilter])

  const sameGroupCards = useMemo(() => {
    const sameGroupCards = data.cards.filter(card => card.quadrant_group === pickedGroup)
    if (groupFilter.length !== 0) {
      return sameGroupCards.filter(card => {
        const ideaGroup = cardsMap.get(String(card.id))?.ideaGroup.id
        return ideaGroup && groupFilter.includes(ideaGroup)
      })
    }
    return sameGroupCards
  }, [data.cards, groupFilter, pickedGroup, cardsMap])

  return (
    <div className={styles.scatterContainer}>
      <div className={styles.scatterHeader}>
        {/* <div>LEGEND</div> */}
        {ideaGroups && (
          <Legend items={ideaGroups} setter={setGroupFilter} CSSstyles={scatterLegendStyles} />
        )}
      </div>
      <div className={styles.scatterScatter}>
        <div className={styles.scatterChart}>
          <Scatter
            options={{ grid: "3x3", widthRem: 40, heightRem: 25 }}
            data={filteredScatterData}
            handlers={{ onClick: onClick }}
            info={{ groups: data.groups, areas: data.areas }}
            hoveredCard={hoveredCard}
          />
        </div>
        {/* TODO вынести */}
        <div
          className={styles.scatterSameGroup}
          // style={{ backgroundColor: quadrantGroupColorsDemo.get(pickedCardGroup) }}
        >
          <p>{groups.find(group => group.index === pickedGroup)?.name}</p>
          <div
            className={styles.scatterSameGroupDivider}
            style={{ backgroundColor: quadrantGroupColorsDemo.get(pickedGroup) }}
          />
          <div className={styles.scatterSameGroupCards}>
            <SimpleBar style={{ maxHeight: "100%", width: "100%" }} autoHide={false}>
              <div className={styles.scatterSameGroupCardsUnderScroll}>
                {sameGroupCards.map(card => {
                  const cardEssentials = cardsMap.get(String(card.id))
                  return (
                    cardEssentials && (
                      <div
                        key={card.id}
                        onMouseMove={() => setHoveredCard(card.id)}
                        onMouseLeave={() => setHoveredCard(null)}
                      >
                        <DashboardCard card={cardEssentials} />
                      </div>
                    )
                  )
                })}
              </div>
            </SimpleBar>
          </div>
        </div>
      </div>
    </div>
  )
}
