import {Translates} from "./translates";

export const ruTranslates: Translates = {
    gotItButton: 'Понятно',
    save: 'Сохранить',
    cardsNotCollectedTittle: 'Спасибо, мы получили ваши ответы!',
    cardsNotCollectedText: 'Однако часть других участников еще не прислала свои ответы. Как только они закончат, вы сможете перейти к следующему заданию. Мы вам сообщим.',
    cardInputPlaceholder: 'Напишите текст карточки...',
    addCardButton: 'Добавить карточку',
    removeCardButton: 'Убрать карточку',
    trashMouseHint: 'Поместить в корзину',
    trashButtonHint: 'Здесь вы можете посмотреть и восстановить все удаленные карточки',
    wrapGroupsHint: 'Свернуть все группы',
    unwrapGroupsHint: 'Развернуть все группы',
    saveNoGRades: "Сохранить без оценок",
    backMouseHint: 'Поместить в банк',
    hintNotification: 'Нажмите на эту иконку, чтобы посмотреть инструкцию',
    mobileHintNotification: 'Нажмите для появления инструкции',
    sendCardsButtonHint: 'Кнопка станет активной, когда вы заполните все карточки',
    deleteGroupHint: 'Удалить группу',
    confirmDeleteGroup: 'Вы уверены, что хотите удалить данную группу? Все карточки данной группы поместятся обратно в банк.',
    binHeader: 'Карточки с непонятным содержанием',
    sortingFinishedTittle: 'Спасибо, мы сохранили ваши ответы.',
    sortingFinishedText: 'Вы можете начать следующий этап',
    group: 'Группа',
    groupCardCounter: 'карточек',
    groupRatedCardCounter: 'оценено',
    addGroupButton: 'Добавить группу',
    finishSortButton: 'Завершить сортировку',
    cardCounter: {
        ratedCards: 'Оцененные карточки',
        sortedCards: 'Отсортированные карточки',
    },
    yes: 'Да',
    no: 'Нет',
    sortingErrors: {
        notAllCardsSorted: 'Вы не отсортировали все карточки',
        emptyGroupName: 'Некоторые имена групп пустые. Дайте им имя',
        binNameUsed: 'bin - системное имя, пожалуйста, переименуйте',
        bankNameUsed: 'bank - системное имя, пожалуйста, переименуйте',
        notAllLikesGiven: 'Вы не поставили нужное количество лайков',
        notAllDislikesGiven: 'Вы не поставили нужное количество дизлайков'
    },
    saveSortingConfirm: 'Вы действительно хотите сохранить сортировку?',
    setLikesRequest: 'Для того, чтобы сохранить сортировку, нужно проставить недостающие оценки.',
    groupContainerPlaceholder: 'Выберите карточки, схожие по смыслу, и нажмите «Добавить группу», чтобы создать группу и поместить их туда.',
    bankPlaceholder: 'Поздравляем! Вы отсортировали все карточки. Можете завершать сортировку.',
    treasury: 'Банк',
    allTheIdeas: 'идей',
    instructionSteps: {
        firstStep: 'Шаг 1',
        secondStep: 'Шаг 2',
        thirdStep: 'Шаг 3',
        repeatSecondThirdSteps: 'Повторяйте шаги 2-3 до тех пор, пока все карточки не будут отсортированы.',
        fourthStep: 'Шаг 4',
        firstStepText: `Изучите <span style="font-size: 0.7rem; font-weight: bold;">БАНК идей</span>, познакомьтесь с карточками внутри, найдите закономерности, темы и карточки с похожими значениями.`,
        secondStepText: `Выберите карточки, объединенные одной темой, и нажмите <span style="font-size: 0.7rem; font-weight: bold;">+ Добавить группу</span>. Карточки будут сгруппированы справа.`,
        thirdStepFirstText: `Дайте группе осмысленное название и переходите к следующей теме...`,
        thirdStepSecondText: `... или создайте пустую группу и переместите туда карточки, кликнув по ним и по названию группы.`,
        repeatSecondThirdStepsFirstText: `Сортируйте карточки по содержанию, а не контексту или своему отношению к ним. Например, объедините карточки A и B, если они обсуждают загрязнение, а не потому что они обе про удачные решения, но в разных областях.`,
        repeatSecondThirdStepsSecondText: `Используйте <span style="font-size: 0.7rem; font-weight: bold;">стрелочки назад</span>, чтобы расформировать группу и вернуть все карточки в <span style="font-size: 0.7rem; font-weight: bold;">БАНК</span>. Но не зацикливайтесь на поиске идеальной структуры, доверьтесь своему первому инстинкту. Идеальная структура появится после объединения мнений всех участников в конце.`,
        repeatSecondThirdStepsThirdText: `Удалите карточки, которые вы не понимаете. Они находятся в <span style="font-size: 0.7rem; font-weight: bold;">корзине</span>, и вы всегда можете их восстановить, если передумаете.`,
    },
    mobileTranslates: {
        sorted: 'сортировано',
        addGroup: 'Добавить группу',
        editGroup: 'Изменить группу',
        done: 'Готово',
        deletedCards: 'Удаленные карточки',
        rateTheCards: 'Оцените карточки',
        YouAreAlmostDone: 'Вы почти закончили! Остается лишь оценить ранее неоцененные карточки. Завершите, чтобы продолжить.',
        binIsEmpty: 'Корзина пока пуста',
        groupIsEmpty: 'Группа пока пуста',
        saveNoGrades: "Сохранить без оценок"
    }
}