// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserChartStyles_userChartContainer__90yKh {\n  height: 100%;\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/project/modules/main/MainDashboard/components/UserChart/UserChartStyles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;AACb","sourcesContent":[".userChartContainer {\n  height: 100%;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userChartContainer": "UserChartStyles_userChartContainer__90yKh"
};
export default ___CSS_LOADER_EXPORT___;
