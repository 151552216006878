import { useRegisterEvents, useSigma } from "@react-sigma/core"
import "@react-sigma/core/lib/react-sigma.min.css"
import { useEffect, useState } from "react"

export function GraphEvents() {
  const registerEvents = useRegisterEvents()
  const sigma = useSigma()
  const [draggedNode, setDraggedNode] = useState<string | null>(null)
  // const [highlightedNode, setHighlightedNode] = useState<string | null>(null)

  useEffect(() => {
    registerEvents({
      // clickNode: event => {
      //   if (!clickedNode) {
      //     setClickedNode(event.node)
      //     sigma.getGraph().setNodeAttribute(event.node, "highlighted", true)
      //   } else {
      //     setClickedNode(null)
      //     sigma.getGraph().setNodeAttribute(event.node, "highlighted", false)
      //   }
      // },

      // On hover
      enterNode: event => {
        // setHighlightedNode(event.node)
        const graph = sigma.getGraph()
        console.log(graph.getNodeAttribute(event.node, "quadrantGroup"))
        graph.forEachNode(node => {
          if (node !== event.node && !graph.neighbors(event.node).includes(node)) {
            graph.setNodeAttribute(node, "color", "#D6D6D6")
            graph.setNodeAttribute(node, "borderColor", "#D6D6D6")
          }
        })
        graph.forEachEdge((edge, attributes, source, target) => {
          if (source === event.node || target === event.node) {
            graph.setEdgeAttribute(edge, "color", graph.getNodeAttribute(event.node, "color"))
          } else {
            graph.setEdgeAttribute(edge, "color", "rgba(214, 214, 214, 0.88)")
          }
        })
      },
      leaveNode: () => {
        // setHighlightedNode(null)
        const graph = sigma.getGraph()
        graph.forEachEdge((edge, attributes) => {
          graph.setEdgeAttribute(edge, "color", "#D6D6D6")
        })
        graph.forEachNode(node => {
          graph.setNodeAttribute(node, "color", graph.getNodeAttribute(node, "defaultColor"))
          graph.setNodeAttribute(
            node,
            "borderColor",
            graph.getNodeAttribute(node, "defaultBorderColor")
          )
        })
      },

      // Drag'n'Drop
      downNode: e => {
        setDraggedNode(e.node)
        sigma.getGraph().setNodeAttribute(e.node, "highlighted", true)
      },
      mousemovebody: e => {
        if (!draggedNode) return
        const pos = sigma.viewportToGraph(e)
        sigma.getGraph().setNodeAttribute(draggedNode, "x", pos.x)
        sigma.getGraph().setNodeAttribute(draggedNode, "y", pos.y)

        e.preventSigmaDefault()
        e.original.preventDefault()
        e.original.stopPropagation()
      },
      mouseup: () => {
        if (draggedNode) {
          setDraggedNode(null)
          sigma.getGraph().removeNodeAttribute(draggedNode, "highlighted")
        }
      },
      mousedown: () => {
        if (!sigma.getCustomBBox()) sigma.setCustomBBox(sigma.getBBox())
      }
    })
  }, [registerEvents, sigma, draggedNode])

  return null
}
