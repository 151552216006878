// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QuadrantGroupStyles_quadrantGroup__v2wtJ {\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  overflow: hidden;\n  flex: 1 0 40%;\n  transition: height .2s;\n  background-color: white;\n  border: 0.0625rem solid black;\n  border-radius: 0.625rem;\n}\n", "",{"version":3,"sources":["webpack://./src/project/modules/main/CardSorting/components/DesktopSorting/Group/QuadrantGroupStyles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,6BAA6B;EAC7B,uBAAuB;AACzB","sourcesContent":[".quadrantGroup {\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  overflow: hidden;\n  flex: 1 0 40%;\n  transition: height .2s;\n  background-color: white;\n  border: 0.0625rem solid black;\n  border-radius: 0.625rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"quadrantGroup": "QuadrantGroupStyles_quadrantGroup__v2wtJ"
};
export default ___CSS_LOADER_EXPORT___;
