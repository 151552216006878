import { MainDashboardTranslates } from "./translates"

const mainDashboardInfoBlockTranslates = {
  ideas: "ideas proposed",
  countries: "countries",
  participants: "participants",
  efficient: "of the ideas the team found effective",
  hasLeader: "of the tasks have volunteers to lead",
  hasFollower: "of the tasks have volunteers to participate",
  averageFollower: "average number of participants per task"
}

const mainDashboardCardsList = {
  header: "Ideas Ranking",
  originalSort: "By originality",
  popularSort: "By popularity"
}

const mainDashboardUserListTranslates = {
  header: "Team Dynamics",
  ideas: "Collecting ideas",
  sort: "Sorting",
  quadrantSort: "Evaluating"
}

export const mainDashboardTranslates: MainDashboardTranslates = {
  infoBlock: mainDashboardInfoBlockTranslates,
  cardsList: mainDashboardCardsList,
  userList: mainDashboardUserListTranslates
}
