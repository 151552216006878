import { GraphologyIdeaGroup } from "../../../../../pages/DashboardPage/types/types"
import { quadrantGroupTextColorsDemo } from "../../ScatterChart/components/Scatter/DemoScatterConstants"

export const quadrantLegend: GraphologyIdeaGroup[] =
[
  {
    id: 8,
    name: "Main focus of the project",
    color: quadrantGroupTextColorsDemo.get(8)
  },
  {
    id: 2,
    name: "Lateral focus of the project",
    color: quadrantGroupTextColorsDemo.get(2)
  },
  {
    id: 6,
    name: "Critical issues outside the project scope",
    color: quadrantGroupTextColorsDemo.get(6)
  },
  {
    id: 0,
    name: "Facultative issues outside the project scope",
    color: quadrantGroupTextColorsDemo.get(0)
  },
  {
    id: 4,
    name: "Low agreement between experts",
    color: quadrantGroupTextColorsDemo.get(4)
  }
]
