import { DashboardIdeaGroup } from "../../../../../../pages/DashboardPage/types/types"
import { CardSortingInfo, QuadrantSortingUserGroup } from "../../types"

export interface CardSortingState {
  info: CardSortingStateInfo
  quadrant_sorting_info?: QuadrantSortingStateInfo
  group_state: GroupState
  user_status: UserSortingStatus
  project_id: number
  project_name: string
  isLoading: boolean
  fetchError: string
  sortStage: SortStage
  quadrant_sort: boolean
  user_groups?: QuadrantSortingUserGroup[]
  card_template?: string[],
  idea_groups?: DashboardIdeaGroup[]
}

export interface QuadrantSortingStateInfo extends Omit<QuadrantSortingInfo, "project_name"> {}

export interface CardSortingStateInfo extends Omit<CardSortingInfo, "project_name"> {}

export interface GroupState {
  actionCounter: number
  isDesktopGroupsCollapsed: boolean
  pickedCards: {
    group: "bank" | number
    cards: number[]
  }
  draggingGroupId?: number
  groups: Group[]
  bank: Card[]
  bin: Card[]
  total_number_of_cards: number
}

export enum UserSortingStatus {
  COLLECTING_CARDS = "collecting_cards",
  WAITING_FOR_SORTING = "waiting_for_sorting",
  SORTING_NOT_STARTED = "sorting_not_started",
  DOING_SORT = "doing_sort",
  SORTING_FINISHED = "sorting_finished",
  QUADRANT_SORTING_NOT_STARTED = "quadrant_sorting_not_started",
  DOING_QUADRANT_SORT = "doing_quadrant_sort",
  FINISHED_QUADRANT_SORT = "finished_quadrant_sort"
}

export enum SortStage {
  MANUAL_SORT = "manual_sort",
  MANUAL_SORT_FINISHED = "manual_sort_finished",
  QUADRANT_SORT = "quadrant_sort",
  QUADRANT_SORT_FINISHED = "quadrant_sort_finished",
}

interface QuadrantSortingInfo {
  sorting_instruction_text: string
  last_step_instruction_text: string
  mobile_sorting_instruction_text: string
  sorting_instruction_title: string,
}

export type GroupColorsType = "#DAF1FF" | "#C5C1F0" | "#FFD556" | string

export interface Group {
  id: number
  name: string
  cards: Card[]
  color: GroupColorsType
  index?: number
  isMobileCollapsed: boolean
}

export type LikeStatus = "liked" | "disliked" | "neutral"

export type GradeStatus = LikeStatus | "unset"

export interface Card {
  id: number
  text: string
  grade_status?: GradeStatus
  group?: number
  animationDestination?: number | null | "to fit"
  idea_group?: number
}

export interface UserStatusOut {
  user_status: UserSortingStatus
}
