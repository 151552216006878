import { Settings } from "sigma/settings"
import { NodeDisplayData, PartialButFor } from "sigma/types"
import drawLabel from "../GraphLabel/GraphLabel"
import { splitToLines } from "../utils"

export default function drawHover(
  context: CanvasRenderingContext2D,
  data: PartialButFor<NodeDisplayData, "x" | "y" | "size" | "label" | "color">,
  settings: Settings
): void {
  const size = data.labelSize || settings.labelSize
  const font = settings.labelFont
  const weight = settings.labelWeight

  data = { ...data, label: data.label || data.hoverLabel }

  context.font = `${weight} ${size}px ${font}`

  // label background
  context.fillStyle = "#FFF" 
  context.shadowOffsetX = 0
  context.shadowOffsetY = 0
  context.shadowBlur = 8
  context.shadowColor = "#000"

  const padding = 4
  const textWidth = Math.min(240, data.label!.length * 8)

  if (typeof data.label === "string") {
    const lines = splitToLines(context, data.label, textWidth)
    const boxWidth = textWidth + size / 2
    const boxHeight = Math.max(size * lines.length + 2 * padding, (data.size + padding) * 2)
    context.beginPath()
    context.moveTo(data.x, data.y + data.size / 2)
    context.lineTo(data.x, data.y + boxHeight / 2)
    context.lineTo(data.x + boxWidth + data.size, data.y + boxHeight / 2)
    context.lineTo(data.x + boxWidth + data.size, data.y - boxHeight / 2)
    context.lineTo(data.x, data.y - boxHeight / 2)
    context.lineTo(data.x, data.y - data.size / 2)
    context.arc(data.x, data.y, data.size + padding, - Math.PI / 2, Math.PI / 2, true)
    context.closePath()
    context.fill()
  } else {
    context.beginPath()
    context.arc(data.x, data.y, data.size + padding, 0, 2 * Math.PI)
    context.closePath()
    context.fill()
  }

  context.shadowOffsetX = 0
  context.shadowOffsetY = 0
  context.shadowBlur = 0

  drawLabel(context, data, settings)
}
