import { MouseEvent, useState } from "react"
import { DashboardCardEssentials } from "../../../../pages/DashboardPage/types/types"
import { DashboardCardHint } from "../DashboardCardHint/DashboardCardHint"
import styles from "./DashboardCardStyles.module.css"

interface DashboardCardProps {
  card: DashboardCardEssentials
}

export function DashboardCard(props: DashboardCardProps) {
  const { card } = props
  const [hintEvent, setHintEvent] = useState<MouseEvent<HTMLDivElement> | null>()

  return (
    <>
      {hintEvent && <DashboardCardHint card={card} event={hintEvent} />}
      <div
        className={styles.dashboardCard}
        style={{
          background: `linear-gradient(to right, ${card.ideaGroup.color} 0.8rem, white 0.8rem)`
        }}
        onMouseMove={event => setHintEvent(event)}
        onMouseLeave={() => setHintEvent(null)}
      >
        <p>{card.text.card}</p>
      </div>
    </>
  )
}
