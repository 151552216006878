import { useCallback, useMemo, useState } from "react"
import { useOutletContext } from "react-router-dom"
import SimpleBar from "simplebar-react"
import {
  ContextType,
  DashboardNodeInfoEssentials,
  GraphologyNode
} from "../../../../pages/DashboardPage/types/types"
import { RadioButton } from "../../../UI/RadioButton/RadioButton"
import { DashboardCard } from "../../../system/components/DashboardCard/DashboardCard"
import { Legend } from "../../../system/components/Legend/Legend"
import { UserPic } from "../../../system/components/UserPic/UserPic"
import styles from "./MainDashboardStyles.module.css"
import { DoughnutChart } from "./components/DoughnutChart/DoughnutChart"
import { UserStatusRow } from "./components/UserStatusRow/UserStatusRow"
import { mainDashboardTranslates as en } from "./translates/enTranslates"
import { mainDashboardTranslates as ru } from "./translates/ruTranslates"
import { MainDashboardTranslates } from "./translates/translates"
import { useTranslates } from "../../../../common/useTranslates"
import { UserChart } from "./components/UserChart/UserChart"
import { PreloadBar } from "../../../system/UX"
import classNames from "classnames"
import { SmallDoughnutChart } from "./components/SmallDoughnutChart/SmallDoughnutChart"

interface MainDashboardProps {}

// TODO разбить
// TODO translates
export function MainDashboard(props: MainDashboardProps) {
  const { ideaGroups, clusters, cardsMap, graphData, userData, info, countries } =
    useOutletContext<ContextType>()
  const translates = useTranslates<MainDashboardTranslates>({ en: en, ru: ru })
  const [ideaGroupsFilter, setIdeaGroupsFilter] = useState<number[]>([])
  const [pagerankDescendingSort, setPagerankDescendingSort] = useState(true)

  const doughnutChartValues: Map<number, number> = useMemo(() => {
    const doughnutChartValues = new Map()
    ideaGroups?.forEach(group => doughnutChartValues.set(group.id, 0))
    graphData.nodes.forEach(card => {
      const groupId = card.attributes.idea_group_id
      doughnutChartValues.set(groupId, doughnutChartValues.get(groupId) + 1)
    })
    return doughnutChartValues
  }, [graphData.nodes, ideaGroups])

  const getDoughnutChartClusterValues: (clusterId: number) => Map<number, number> = useCallback(
    clusterId => {
      const doughnutChartValues = new Map()
      ideaGroups?.forEach(group => doughnutChartValues.set(group.id, 0))
      graphData.nodes
        .filter(card => card.attributes.author.cluster === clusterId)
        .forEach(card => {
          const groupId = card.attributes.idea_group_id
          doughnutChartValues.set(groupId, doughnutChartValues.get(groupId) + 1)
        })
      return doughnutChartValues
    },
    [graphData.nodes, ideaGroups]
  )

  const chartLegendStyles = useMemo(
    () => ({
      container: styles.mainDashboardLegend,
      checked: styles.mainDashboardLegendRowActive,
      item: styles.mainDashboardLegendRow,
      itemHover: styles.mainDashboardLegendHover,
      iconSize: "0.8rem"
    }),
    []
  )

  const filteredCards = useMemo(() => {
    const cardsByGroups: GraphologyNode<DashboardNodeInfoEssentials>[][] = []
    ideaGroups?.forEach(group => {
      cardsByGroups.push(
        graphData?.nodes
          .filter(card => card.attributes.idea_group_id === group.id)
          .sort((a, b) => b.attributes.pagerank - a.attributes.pagerank)
      )
    })
    const sortedCards = cardsByGroups.reduce((acc, item) => {
      acc.push(...item)
      return acc
    }, [])
    if (ideaGroupsFilter.length !== 0 && sortedCards.length !== 0) {
      return sortedCards.filter(card => ideaGroupsFilter.includes(card.attributes.idea_group_id))
      // .sort((a, b) =>
      //   pagerankDescendingSort
      //     ? b.attributes.pagerank - a.attributes.pagerank
      //     : a.attributes.pagerank - b.attributes.pagerank
      // )
    } else {
      return sortedCards
      // return [...graphData.nodes].sort((a, b) =>
      //   pagerankDescendingSort
      //     ? b.attributes.pagerank - a.attributes.pagerank
      //     : a.attributes.pagerank - b.attributes.pagerank
      // )
    }
  }, [ideaGroups, ideaGroupsFilter, graphData])

  return !(userData && countries) ? (
    <PreloadBar isFullPage={true} />
  ) : (
    <SimpleBar
      style={{
        height: "100%",
        width: "100%"
      }}
    >
      <div className={styles.mainDashboardContainer}>
        <div className={styles.mainDashboardMainBlock}>
          <div className={styles.mainDashboardCardsBlock}>
            <div className={styles.mainDashboardDoughnutChart}>
              <div className={styles.mainDashboardDoughnuts}>
                {ideaGroups && (
                  <div
                    className={classNames(
                      styles.mainDashboardDoughnutClusterChart,
                      styles.mainDashboardDoughnutMainChart
                    )}
                  >
                    {/* <p>All divisions</p> */}
                    <DoughnutChart
                      ideaGroups={ideaGroups}
                      values={doughnutChartValues}
                      sizeRem={10}
                    />
                  </div>
                )}
                {ideaGroups && clusters && (
                  <>
                    {clusters.map(cluster => (
                      <div className={styles.mainDashboardDoughnutClusterChart}>
                        <p>{cluster.name.includes("SME") ? "SME" : cluster.name}</p>
                        <SmallDoughnutChart
                          ideaGroups={ideaGroups}
                          values={getDoughnutChartClusterValues(cluster.id)}
                          sizeRem={6}
                        />
                      </div>
                    ))}
                  </>
                )}
              </div>
              {ideaGroups && (
                <Legend
                  items={ideaGroups}
                  CSSstyles={chartLegendStyles}
                  setter={setIdeaGroupsFilter}
                />
              )}
            </div>
            <div className={styles.mainDashboardCardsList}>
              <p>{translates.cardsList.header}</p>
              {/* <div className={styles.mainDashboardCardsListFilters}>
                <RadioButton
                  name="descending"
                  onClick={() => {
                    setPagerankDescendingSort(true)
                  }}
                  label={translates.cardsList.originalSort}
                  checked={pagerankDescendingSort}
                />
                <RadioButton
                  name="ascending"
                  onClick={() => {
                    setPagerankDescendingSort(false)
                  }}
                  label={translates.cardsList.popularSort}
                  checked={!pagerankDescendingSort}
                />
              </div> */}
              <div className={styles.mainDashboardCardsListCards}>
                <SimpleBar style={{ maxHeight: "100%", width: "100%" }} autoHide={false}>
                  <div className={styles.mainDashboardCardsListUnderScroll}>
                    {filteredCards.map(card => {
                      const cardEssentials = cardsMap.get(card.key)
                      return cardEssentials ? (
                        <DashboardCard key={card.key} card={cardEssentials} />
                      ) : null
                    })}
                  </div>
                </SimpleBar>
              </div>
            </div>
          </div>
          <div className={styles.mainDashboardInfoChartBlock}>
            <div className={styles.mainDashboardInfoBlock}>
              <div className={styles.mainDashboardInfoBlockCell}>
                <span>{graphData.nodes.length}</span>
                <p>{translates.infoBlock.ideas}</p>
              </div>
              <div className={styles.mainDashboardInfoBlockCell}>
                <span>{userData?.length}</span>
                <p>{translates.infoBlock.participants}</p>
              </div>
              <div className={styles.mainDashboardInfoBlockCell}>
                <span>{countries?.length}</span>
                <p>{translates.infoBlock.countries}</p>
              </div>
              {/* <div className={styles.mainDashboardInfoBlockCell}>
            <span>{info.efficient}%</span>
            <p>{translates.infoBlock.efficient}</p>
          </div>
          <div className={styles.mainDashboardInfoBlockCell}>
            <span>{info.has_leader}%</span>
            <p>{translates.infoBlock.hasLeader}</p>
          </div>
          <div className={styles.mainDashboardInfoBlockCell}>
            <span>{info.has_follower}%</span>
            <p>{translates.infoBlock.hasFollower}</p>
          </div>
          <div className={styles.mainDashboardInfoBlockCell}>
            <span>{info.average_follower}</span>
            <p>{translates.infoBlock.averageFollower}</p>
          </div> */}
            </div>
            <div className={styles.mainDashboardUserList}>
              <UserChart />
              {/* <div className={styles.mainDashboardUserListHeader}>
              <p>{translates.userList.header}</p>
              <div className={styles.mainDashboardUserListRow}>
                <p></p>
                <p>{translates.userList.ideas}</p>
                <p>{translates.userList.sort}</p>
                <p>{translates.userList.quadrantSort}</p>
              </div>
            </div>
            <div className={styles.mainDashboardUserListUsers}>
              <SimpleBar style={{ maxHeight: "100%" }} autoHide={false}>
                <div className={styles.mainDashboardUserListUnderScroll}>
                  {clusters?.map((cluster, index) => (
                    <div key={cluster.id} className={styles.mainDashboardUserListCluster}>
                      <div className={styles.mainDashboardUserListRow}>
                        <p className={styles.mainDashboardUserListClusterName}>{cluster.name}</p>
                      </div>
                      {userData &&
                        userData
                          .filter(user => user.cluster === cluster.id)
                          .map(user => (
                            <div key={user.id} className={styles.mainDashboardUserListRow}>
                              <UserPic userId={user.id} showName={true} />
                              <UserStatusRow
                                status={user.user_status}
                                finishedCollecting={user.user_finished_collecting}
                              />
                            </div>
                          ))}
                    </div>
                  ))}
                  {clusters?.length === 0 && (
                    <div className={styles.mainDashboardUserListCluster}>
                      {userData &&
                        userData.map(user => (
                          <div key={user.id} className={styles.mainDashboardUserListRow}>
                            <div className={styles.mainDashboardUserPicRow}>
                              <UserPic userId={user.id} showName={true} />
                            </div>
                            <UserStatusRow
                              status={user.user_status}
                              finishedCollecting={user.user_finished_collecting}
                            />
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </SimpleBar>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </SimpleBar>
  )
}
